<template>
  <VNavigationDrawer
    floating
    temporary
    :rail="false"
    class="drawer"
    v-bind="$attrs"
    @update:model-value="trig"
  >
    <slot />
  </VNavigationDrawer>
</template>

<script setup lang="ts">
import { VNavigationDrawer } from 'vuetify/components/VNavigationDrawer'

const emit = defineEmits(['update:model-value'])
const trig = (e) => {
  if (window && !e)
    window.document.getElementsByTagName('html')[0].classList.remove('hidden')
  emit('update:model-value')
}
</script>

<style scoped lang="scss">
.drawer {
  &.bottom {
    padding-top: 24px;
    padding-bottom: 16px;
    border-radius: 16px 16px 0 0;
    min-height: 350px;
    max-height: 98%;
    overflow-y: hidden;

    &.btn-apply {
      padding-bottom: 80px;
    }
    &:before {
      content: '';
      position: absolute;
      width: 80px;
      height: 4px;
      border-radius: 4px;
      background: color(gray-2);
      top: 12px;
      left: calc(50% - 40px);
      pointer-events: none;
    }

    &:deep(.v-navigation-drawer__content) {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
